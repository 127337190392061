//
// Define any Norfolk specific CSS here
// --------------------------------------------------

.permits-index-page {
    .applications.row {
        margin-bottom: 50px;
    }

    .permits.row {
        margin-bottom: 50px;
    }
}

.permits-show-page {
    .panel-overview, .panel-fees {
        table tr:first-child {
            td, th {
                border-top: none;
            }
        }

        table {
            margin-bottom: 0;

            td small {
                color: #888;
                font-style: oblique;
            }
        }
    }
}

//
// Norfolk Legacy Permit Forms
// --------------------------------------------------

.permits-create-as-page, .permits-create-page {
    .panel-permit-form {
        form table {
            // Everything below is copied from the legacy application.css file
            .text{
                font-size: 18px;
                font-family: arial;
            }

            .head {
                font-size: 18px;
                font-family: arial;
            }

            .back {
                font-size: 18px;
                font-family: arial;
            }

            label {
                font-weight: normal;
            }

            .no-left-padding {
                padding-left: 0px !important;
            }

            .table-striped td label {
                margin-right: 10px;
            }

            .with-auto {
                width: auto !important;
            }

            .viewcart-width {
                width: 35.667% !important;
            }

            .fullwidth {
                width: 100%;
            }

            .date-style {
                width: 120px;
                margin-right: 5px;
            }

            .number-style {
                width: 78px;
                margin-right: 5px;
            }

            .license-style {
                width: 184px;
                margin-right: 5px;
            }

            .other-description {
                width: 310px;
            }

            .permit-form-header {
                margin-bottom: 0px;
            }

            .permit-form-header td.head {
                border-left : 1px solid;
                border-right : 1px solid;
            }

            #opencut_div, #pipe_cnt_div, #streets_cnt_div {
                min-height: 35px;
            }

            .required-label {
                color: #a94442;
            }

            input.error, select.error, textarea.error {
                border: 1px solid #a94442;
            }

            label.error {
                font-size: 15px !important;
                font-style: normal !important;
                color: #a94442;
            }

            textarea {
                font-family: inherit;
                font-size: 18px !important;
            }
        }
    }
}
