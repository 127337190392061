.permits-index-page .applications.row {
  margin-bottom: 50px;
}
.permits-index-page .permits.row {
  margin-bottom: 50px;
}
.permits-show-page .panel-overview table tr:first-child td,
.permits-show-page .panel-fees table tr:first-child td,
.permits-show-page .panel-overview table tr:first-child th,
.permits-show-page .panel-fees table tr:first-child th {
  border-top: none;
}
.permits-show-page .panel-overview table,
.permits-show-page .panel-fees table {
  margin-bottom: 0;
}
.permits-show-page .panel-overview table td small,
.permits-show-page .panel-fees table td small {
  color: #888;
  font-style: oblique;
}
.permits-create-as-page .panel-permit-form form table .text,
.permits-create-page .panel-permit-form form table .text {
  font-size: 18px;
  font-family: arial;
}
.permits-create-as-page .panel-permit-form form table .head,
.permits-create-page .panel-permit-form form table .head {
  font-size: 18px;
  font-family: arial;
}
.permits-create-as-page .panel-permit-form form table .back,
.permits-create-page .panel-permit-form form table .back {
  font-size: 18px;
  font-family: arial;
}
.permits-create-as-page .panel-permit-form form table label,
.permits-create-page .panel-permit-form form table label {
  font-weight: normal;
}
.permits-create-as-page .panel-permit-form form table .no-left-padding,
.permits-create-page .panel-permit-form form table .no-left-padding {
  padding-left: 0px !important;
}
.permits-create-as-page .panel-permit-form form table .table-striped td label,
.permits-create-page .panel-permit-form form table .table-striped td label {
  margin-right: 10px;
}
.permits-create-as-page .panel-permit-form form table .with-auto,
.permits-create-page .panel-permit-form form table .with-auto {
  width: auto !important;
}
.permits-create-as-page .panel-permit-form form table .viewcart-width,
.permits-create-page .panel-permit-form form table .viewcart-width {
  width: 35.667% !important;
}
.permits-create-as-page .panel-permit-form form table .fullwidth,
.permits-create-page .panel-permit-form form table .fullwidth {
  width: 100%;
}
.permits-create-as-page .panel-permit-form form table .date-style,
.permits-create-page .panel-permit-form form table .date-style {
  width: 120px;
  margin-right: 5px;
}
.permits-create-as-page .panel-permit-form form table .number-style,
.permits-create-page .panel-permit-form form table .number-style {
  width: 78px;
  margin-right: 5px;
}
.permits-create-as-page .panel-permit-form form table .license-style,
.permits-create-page .panel-permit-form form table .license-style {
  width: 184px;
  margin-right: 5px;
}
.permits-create-as-page .panel-permit-form form table .other-description,
.permits-create-page .panel-permit-form form table .other-description {
  width: 310px;
}
.permits-create-as-page .panel-permit-form form table .permit-form-header,
.permits-create-page .panel-permit-form form table .permit-form-header {
  margin-bottom: 0px;
}
.permits-create-as-page .panel-permit-form form table .permit-form-header td.head,
.permits-create-page .panel-permit-form form table .permit-form-header td.head {
  border-left: 1px solid;
  border-right: 1px solid;
}
.permits-create-as-page .panel-permit-form form table #opencut_div,
.permits-create-page .panel-permit-form form table #opencut_div,
.permits-create-as-page .panel-permit-form form table #pipe_cnt_div,
.permits-create-page .panel-permit-form form table #pipe_cnt_div,
.permits-create-as-page .panel-permit-form form table #streets_cnt_div,
.permits-create-page .panel-permit-form form table #streets_cnt_div {
  min-height: 35px;
}
.permits-create-as-page .panel-permit-form form table .required-label,
.permits-create-page .panel-permit-form form table .required-label {
  color: #a94442;
}
.permits-create-as-page .panel-permit-form form table input.error,
.permits-create-page .panel-permit-form form table input.error,
.permits-create-as-page .panel-permit-form form table select.error,
.permits-create-page .panel-permit-form form table select.error,
.permits-create-as-page .panel-permit-form form table textarea.error,
.permits-create-page .panel-permit-form form table textarea.error {
  border: 1px solid #a94442;
}
.permits-create-as-page .panel-permit-form form table label.error,
.permits-create-page .panel-permit-form form table label.error {
  font-size: 15px !important;
  font-style: normal !important;
  color: #a94442;
}
.permits-create-as-page .panel-permit-form form table textarea,
.permits-create-page .panel-permit-form form table textarea {
  font-family: inherit;
  font-size: 18px !important;
}
